import React, { useState } from 'react';
import { Modal, Form, Input, Button, Tag, notification} from 'antd';
import CrudIntegration from '../../../logic/crudIntegration';


const UploadBeneficiaryListModal = (props) => {
    const [visible, setVisible] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState(null);

    const showModal = () => {
        setVisible(true);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };


    const handleCancel = () => {
        setVisible(false);
    };

    const uploadFile = async data => {
        setUploading(true)
        let formData = new FormData();
        formData.append('file', file);
        formData.append('sme', props.sme.id);
        const records = await new CrudIntegration().create(
            'api/v1/admin-portal/records/upload-beneficiary-list/',
            formData,
            'multipart/form-data'
        );
        try{
            if (records !== 400){ props.getSmes()}  
        } catch(err){
            notification.error({
                description: 'Sorry, an error occurred while creating the SME'
            })
        }
        setUploading(false);
    };

    return (
        <>
            <Tag color="blue" onClick={showModal}>Upload Beneficiary List</Tag>
            <Modal
                title="Upload Beneficiary List"
                open={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form layout='vertical' onFinish={uploadFile}>
                    <Form.Item 
                        label="File" 
                        name="file"
                        rules={[{
                            required: true,
                            message: 'Please upload the file to consider' 
                        }]}>
                        <Input type='file' onChange={handleFileChange}/>
                    </Form.Item>
                    <Form.Item style={{marginTop: '20px'}}>
                        <Button 
                            className="bg-[#413E79] hover:scale-102 hover:bg-[#F8B34E] hover:shadow-soft-xs active:opacity-85 active:bg-[#F8B34E]"
                            type="primary" 
                            htmlType="submit" 
                            style={{width: '100%'}}
                            loading={uploading}
                            >
                            Upload List
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default UploadBeneficiaryListModal;