import React, { useState } from 'react';
import { Modal, Form, Input, Button, Select} from 'antd';
import { productsSlice } from '../../../store/reducers/products';

const AddSmeModal = (props) => {
    const [visible, setVisible] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const showModal = () => {
        setVisible(true);
    };


    const handleCancel = () => {
        setVisible(false);
    };

    const addSme = async data => {
        setSubmitting(true);
        props.addSme(data);
        setSubmitting(false);
      };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Add SME
            </Button>
            <Modal
                title="Add SME"
                open={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form layout='vertical' onFinish={addSme}>
                    <Form.Item 
                        label="Product" 
                        name="product"
                        rules={[{
                            required: true,
                            message: 'Please select the product' 
                        }]}
                    >
                        <Select>
                            {
                                props.products.map((product, index) => {
                                    return <Select.Option value={product.id}>{product.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        label="Name" 
                        name="name"
                        rules={[{
                            required: true,
                            message: 'Please enter the name of the SME' 
                        }]}
                        >
                        <Input />
                    </Form.Item>
                    
                    <Form.Item 
                        label="SME Address" 
                        name="address"
                        rules={[{
                            required: true,
                            message: 'Please enter the address of the SME' 
                        }]}
                        >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label="Contact Person Name" 
                        name="contact_person"
                        rules={[{
                            required: true,
                            message: 'Please enter the name of the contact person' 
                        }]}
                        >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label="Contact Person Number" 
                        name="contact_person_phone_number"
                        rules={[{
                            required: true,
                            message: 'Please enter the phone number of the contact person' 
                        }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label="Contact Person Email" 
                        name="contact_person_email"
                        rules={[{
                            required: false,
                            message: 'Please enter the email of the contact person' 
                        }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button 
                            className="bg-[#413E79] hover:scale-102 hover:bg-[#F8B34E] hover:shadow-soft-xs active:opacity-85 active:bg-[#F8B34E]"
                            type="primary" 
                            htmlType="submit" 
                            style={{width: '100%'}}
                            loading={submitting}
                            >
                            Add SME
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddSmeModal;